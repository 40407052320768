import './App.css';
import React, { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Table from './components/Table';

const WS_URL = 'wss://ws.mamotik.com/ws';

function App() {
  const [data, setData] = useState([]);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: false,
      shouldReconnect: () => true,
    },
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({key: "admin"})
    }
  }, [readyState])

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    const id = setInterval(() => {
      const recv = lastJsonMessage;
      console.log(recv)
      setData(recv?.result)
      if (readyState === ReadyState.OPEN) {
        sendJsonMessage({key: "admin"})
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };

  })

  return (
    <div className="App">
      <header className="App-header">
        <h2>
          Mamotik Admin Panel
        </h2>
        <Table data={data} />
      </header>
    </div>
  );
}

export default App;