// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    margin-top: 20px;
    width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".table-container {\r\n    margin-top: 20px;\r\n    width: 100%;\r\n  }\r\n  \r\n  table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n  }\r\n  \r\n  th,\r\n  td {\r\n    padding: 8px;\r\n    border-bottom: 1px solid #ddd;\r\n    text-align: left;\r\n  }\r\n  \r\n  th {\r\n    font-weight: bold;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
